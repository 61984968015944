import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Button,
  Breadcrumb 
} from "../../styles/styledComponents";
import { useNavigate } from "react-router-dom"; // Importar useNavigate
import { deleteStore, getAllStores, updateStoreWebVersion } from "./services/shop.service"; // Importar la función para obtener todas las tiendas
import { Link } from "react-router-dom";
import { ErrorAlert } from "../../components/ErrorAlert";
import { PublishStore } from "./component/PublishStore"; // Importar el nuevo componente
import { DataTableTienda } from "../../styles/DataTable";

const API_URL = process.env.REACT_APP_ECOMMERCE_API_URL; 
const STORE_FOLDER = process.env.REACT_APP_ECOMMERCE_STORE_FOLDER;

export function Tiendas() {
  const [expandedStoreId, setExpandedStoreId] = useState(null);
  const navigate = useNavigate(); // Inicializar el hook para navegar
  const [stores, setStores] = useState([]); // Inicializar el estado de las tiendas
  const [loading, setLoading] = useState(true); // Inicializar el estado de carga
  const [error, setError] = useState(null); // Inicializar el estado de error
  const [filterData, setFilterData] = useState({
    startDateTime: "2024-10-10T15:36:37.831Z",
    endDateTime: "2025-10-10T15:36:37.831Z",
  });

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const response = await getAllStores(filterData); // Obtener todas las tiendas
        console.log("datos", response);
        if (response && Array.isArray(response.stores)) {
          setStores(response.stores); // Guardar las tiendas en el estado
        } else {
          console.error("Expected an array but got:", response.stores);
          setError("Invalid data format");
        }
      } catch (error) {
        setError(error); // Almacena el error en el estado para que el componente ErrorAlert lo procese
      } finally {
        setLoading(false); // Detener el estado de carga
      }
    };
    fetchStores(); // Llamar a la función cuando se monte el componente
  }, []);

  const toggleExpand = (storeId) => {
    setExpandedStoreId(expandedStoreId === storeId ? null : storeId);
  };

  const handleDeleteStore = async (storeId) => {
      const response = await deleteStore(storeId);
      if (response) {
        const formData = new FormData();
        formData.append("storeId", storeId);
        console.log("response", response);
        window.location.reload();
      } else {
        console.log("Error al eliminar la tienda");
      }
    };

    const handleUpdateWebVersion = async (storeId) => {
      const response = await updateStoreWebVersion(storeId);
      if (response) {
        const formData = new FormData();
        formData.append("storeId", storeId);
        console.log("response", response);
        window.location.reload();
      } else {
        console.log("Error al actualizar version la tienda");
      }
    };

  // Añadir función para manejar la publicación exitosa
const handlePublishSuccess = () => {
  // Aquí puedes recargar las tiendas o mostrar un mensaje de éxito
  window.location.reload();

};

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  const columns = [
    { accessor: "storeName", label: "Nombre" },
    { accessor: "status", label: "Estado" },
  ];

  const actions = [
    {
      label: "🌐 Ver Tienda",
      onClick: (row) => window.open(`${STORE_FOLDER}/${row.companyId}/${row.storeName}/index.html`, "_blank"),
    },
    {
      label: "🛒 Productos",
      onClick: (row) => navigate("/tienda-productos", { state: { storeId: row.id } }),
    },
    {
      label: "📋 Pedidos y contactos",
      onClick: (row) => navigate("/tienda-pedidos", { state: { storeId: row.id } }),
    },
    {
      label: "✏️ Editar Nombre de la Tienda Web",
      onClick: (row) => navigate("/editar-info-tienda", { state: { storeId: row.id } }),
    },
    {
      label: "⚙️ Configurar Tienda Web",
      onClick: (row) => navigate("/editar-tienda", { state: { storeId: row.id } }),
    },
    {  component: (row) => (
      <PublishStore
        key={`publish-${row.id}`} // Añadir una clave única
        storeId={row.id}
        onSuccess={handlePublishSuccess}
        isActive={row.status === "ACTIVATE"}
      />
    ),  
    },
    {
      label: "🗑️ Eliminar Tienda",
      onClick: (row) => handleDeleteStore(row.id)
    },
    {
      label: "🔄 Actualizar Versión de la Tienda",
      onClick: (row) => handleUpdateWebVersion(row.id)
    },
  ];

  return (
    <Container>
      {error && <ErrorAlert error={error} />}
      <TitleContainer>
        <Title>Mis Tiendas</Title>
        <Breadcrumb items={[
          { name: "Inicio", link: "#" },
          { name: "Tiendas", link: "/tienda" }
        ]} />
      </TitleContainer>      
      <Card>
        <Button style={buttonStyle} onClick={() => navigate("/crear-tienda")}>Crear tienda</Button>{" "}
        {/* Redirigir al formulario */}
        <DataTableTienda 
          columns={columns} 
          data={stores} 
          actions={actions} 
          expandedRowId={expandedStoreId} 
          onRowToggle={toggleExpand} 
        />
        
      </Card>
    </Container>
  );
}

const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#d34343', // Color azul
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  marginTop: '20px',
};

const Th = styled.th`
  border-bottom: 1px solid #ddd;
  padding: 10px;
  text-align: left;
`;

const Td = styled.td`
  border-bottom: 1px solid #ddd;
  padding: 10px;
  text-align: left;
`;

const ButtonMore = styled.button`
  font-family: "Roboto", sans-serif;
  background-color: #b9b9b9;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 2px 16px;
  cursor: pointer;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  &:hover {
    background-color: #acacac;
  }
  &:active {
    transform: scale(0.95); /* Pequeño efecto de "clic" al hacer click */
  }
`;

const ActionsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ActionButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #e0e0e0;
  }
`;
